var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  }, [_c('path', {
    attrs: {
      "d": "M7 22H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h2a1 1 0 0 0 0-2H5a5.006 5.006 0 0 0-5 5v14a5.006 5.006 0 0 0 5 5h2a1 1 0 0 0 0-2Z"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "d": "m23 11-15.777.032a2.018 2.018 0 0 1 .326-.446l3.879-3.879a1 1 0 1 0-1.414-1.414L6.133 9.172a4 4 0 0 0 0 5.656l3.879 3.879a1 1 0 1 0 1.414-1.414l-3.879-3.879a2.01 2.01 0 0 1-.291-.382L23 13a1 1 0 0 0 0-2Z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }